<template>
  <div class="container">
    <img src="../images/error.svg" alt="">
    <p>身份验证失败</p>
    <van-button size="normal" round color="#f49b0d" block @click="close">重试</van-button>
  </div>
</template>

<script>
export default {
  name: "Success",
  data() {
    return {
      
    };
  },
  created() {},
  methods: {
   close(){
    this.$router.go(-2);
   }
  },
};
</script>

<style scoped lang="scss">
.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  img{
    width: 100px;
  }
  p{
    font-size: 14px;
    line-height: 14px;
    margin-top: 20px;
    margin-bottom: 50px;
  }
}
</style>
